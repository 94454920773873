/* eslint-disable require-jsdoc */
import React, {type ReactNode} from 'react';
import {ThemeProvider, createTheme, useTheme} from '@mui/material/styles';
import useLocales from '@/hooks/useLocales';

type ThemeLocalizationProps = {
  children: ReactNode;
};

export default function ThemeLocalization({children}: ThemeLocalizationProps) {
  const defaultTheme = useTheme();
  const {currentLang} = useLocales();

  const theme = createTheme(defaultTheme, currentLang.systemValue);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
