/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable require-jsdoc */
import React, {type ReactNode, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import {getDashboardPaths} from '@/router/paths';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({children}: GuestGuardProps) {
  const {isAuthenticated} = useAuth();
  const navigate = useNavigate();
  const org = window.localStorage.getItem('organization');

  useEffect(() => {
    checkAuth();
  }, [isAuthenticated]);

  const checkAuth = () => {
    if (isAuthenticated && org) {
      return navigate(getDashboardPaths().root);
    }
  };
  return <>{children}</>;
}
