import React, {type ReactNode, useState} from 'react';
import type {Task} from '@/@types/enum';
export const SideWidgetContext = React.createContext(null);

type SideWidgetProps = {
  children?: ReactNode;
};

export const SideWidgetProvider = ({children}: SideWidgetProps) => {
  const [opened, setOpened] = useState<boolean>(true);
  const [value, setValue] = useState<string>('notes');
  const [tasks, setTasks] = useState<Task[]>([]);

  return (
    <SideWidgetContext.Provider
      value={{opened, setOpened, value, setValue, tasks, setTasks}}
    >
      {children}
    </SideWidgetContext.Provider>
  );
};
