/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
export enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

export type RowsState = {
  page: number;
  pageSize: number;
  loading: boolean;
};

export type ContactInfo = {
  id?: number;
  name?: string;
  function?: string;
  email?: string;
  phone?: string;
};

export type Phase = {
  id?: number;
  name: string;
  color: string;
  total_stages?: number;
  total_projects?: number;
  description: string;
  order: number;
  created_at?: Date;
  updated_at?: Date;
};

export type Stage = {
  id: number;
  name: string;
  total_projects?: number;
  description: string;
  order?: number;
  phase?: Phase;
};

export type StaffMember = {
  id?: number;
  name?: string;
};

export type StageWorkplan = {
  description: string;
  id: number;
  name: string;
  order: number;
  phase: Phase;
};

export type PhaseWorkplan = {
  id: number;
  name: string;
  color: string;
};

export type Task = {
  id?: number;
  name: string;
  reporter: User;
  assignee: User;
  title?: string;
  description: string;
  project: Project;
  step?: Step;
  created_at?: Date;
  updated_at?: Date;
  deadline: Date;
  important: boolean;
  status: 'TO_DO' | 'DONE';
};

export type Step = {
  id: number;
  template: {
    id: number;
    name: string;
    stage: number;
    order: number;
    color: string;
  };
  stage?: number;
  name?: string;
  status: string;
  order: number;
};

export type StepInstance = {
  color: string;
  id: number;
  name: string;
  order: number;
  description: string;
  stage: number;
  status: string;
};

export type Project = {
  id: number;
  name: string;
  start_date: Date;
  expected_end_date: Date;
  location: string;
  current_step: {
    id: number;
    template: {
      id: number;
      name: string;
      description: string;
      stage: number;
      order: number;
      color: string;
    };
    status: string;
    order: number;
  };
  client: {
    id: number;
    name: string;
  };
  team: {
    id: number;
    leader: {
      id: number;
      organization_user: {
        id: number;
        name: string;
        email: string;
        role: string;
      };
      role: string;
    };
  };
  extra_fields: string;
  created_at: Date;
  updated_at: Date;
  created_by: {
    id: number;
    name: string;
    email: string;
    role: string;
  };
  updated_by: {
    id: number;
    name: string;
    email: string;
    role: string;
  };
};

export type Workplan = {
  id?: number;
  title?: string;
  description: string;
  color?: string;
  project: number;
  start_at: Date;
  end_at: Date;
  department: number;
  assignees: number[];
  reporter: number[];
  icon?: number;
  tags: number[];
  stage: number;
  resourceId?: number;
};

export type Form = {
  id?: number;
  name: string;
  description?: string;
  step_templte: number;
  form_field: {
    field: number;
    required?: boolean;
  };
};

export type Components = {
  id?: number;
  component_type?: string;
  name?: string;
  description?: string;
};

export type Country = {
  name: string;
  code: string;
};

export type User = {
  id?: number;
  email: string;
  first_name?: string;
  last_name?: string;
  name?: string;
  user_id?: number;
  role?: string;
};

export type Staff = {
  id: number;
  email?: string;
  name: string;
  deaprtments?: Departments;
  preferred_language?: string;
  project_teams?: [];
  role?: string;
};

export type Note = {
  id?: number;
  name: string;
  created_by?: User;
  title?: string;
  description: string;
  project: Project;
  step?: Step;
  created_at?: Date;
  updated_at?: Date;
  important: boolean;
  status: 'OPEN' | 'CLOSED';
  communication_method: string;
  assignees: User[];
};

export type Appointment = {
  id?: number;
  name: string;
  created_by?: User;
  updated_by?: User;
  title?: string;
  description: string;
  project: Project;
  step?: Step;
  scheduled_for: Date;
  important: boolean;
  status: 'PENDING' | 'CONFIRMED' | 'CANCELED';
  communication_method: string;
  assignees: User[];
};

export type MeetingMinutes = {
  id: number;
  title: string;
  note: string;
  meeting_type: string;
  frequency: string;
  meeting_date: Date;
  location: string;
  started_at: string;
  ended_at: string;
  projects: Project[];
  required_attendees: User[];
  optional_attendees: User[];
  absentees: User[];
  chair_person: User;
  secretary: User;
};

export type Departments = {
  id?: number;
  name: string;
  description: string;
  lead?: number;
  managers?: number[];
  members?: number[];
  organization: number;
};
export type Member = {
  id: number;
  email: string;
  name: string;
  role: string;
  disabled?: boolean;
};

export type Fields = {
  id: number;
  name: string;
  description: string;
  label: string;
  help_text: string;
  placeholder: string;
  value: string;
  source: string;
  internal_type: string;
};

export interface StepTemplateComponent {
  component: number;
}

export interface StepTemplate {
  id: number;
  name: string;
  stage: number;
  order: number;
  color: string;
  step_template_components: StepTemplateComponent[];
}

export interface Filter {
  operation: string;
  fieldname: string;
  lookup: string;
  value: string;
}

export interface Notification {
  action: string;
  action_target_field: string;
  actor: Member;
  created_at: Date;
  id: number;
  read_timestamp: Date;
  recipient_relation_with_target: string;
  target_object_id: number;
  target_object_name: string;
  target_object_type: string;
}

export type ProjectPermission = {
  id: number;
  content_type:
    | {
        id: number;
        model: string;
      }
    | number;
  object_id: number;
  role: string;
  permission: string;
};

export type DepartmentPermission = {
  id: number;
  content_type:
    | {
        id: number;
        model: string;
      }
    | number;
  department:
    | {
        id: number;
        name: string;
      }
    | number;
  object_id: number;
  role: string;
  permission: string;
};

export type UserPermission = {
  id: number;
  content_type:
    | {
        id: number;
        model: string;
      }
    | number;
  object_id: number;
  organization_user:
    | {
        id: number;
        name: string;
        email: string;
        role: string;
      }
    | number;
  permission: string;
};
