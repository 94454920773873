/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import useLocales from '@/hooks/useLocales';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Modal, Box} from '@mui/material';
import {XMarkIcon} from '@heroicons/react/20/solid';

type ItemUploadProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNew: React.Dispatch<React.SetStateAction<boolean>>;
};

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  borderRadius: '8px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 1
};

const EditOptionWarning = ({open, setOpen, setIsNew}: ItemUploadProps) => {
  const {translate} = useLocales();

  const handleClose = () => {
    setOpen(false);
    setIsNew(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <DialogTitle>
            <div className="flex justify-end items-center">
              <XMarkIcon
                onClick={() => handleClose()}
                className="h-7 w-7 cursor-pointer text-gray-300"
              />
            </div>
          </DialogTitle>
          <DialogContent>
            <p>{translate('edit_option_text')}</p>
          </DialogContent>
        </Box>
      </Modal>
    </>
  );
};

export default EditOptionWarning;
