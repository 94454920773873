import React, {type ReactNode, useState} from 'react';
import type {Task} from '@/@types/enum';
export const SideWidgetContext = React.createContext(null);

type SideWidgetProps = {
  children?: ReactNode;
};

export const SideWidgetProvider = ({children}: SideWidgetProps) => {
  const [opened, setOpened] = useState<boolean>(true);
  const [value, setValue] = useState<string>('notes');
  const [tasks, setTasks] = useState<Task[]>([]);
  const [modeFilter, setModeFilter] = useState<string>('');
  const [stagesOfPhase, setStagesOfPhase] = useState<any[]>([]);
  const [projectsOfClient, setProjectsOfClient] = useState<any[]>([]);
  const [projectSelectedView, setProjectSelectedView] = useState<any[]>([]);
  const [stageSelectedView, setStageSelectedView] = useState<any[]>([]);

  return (
    <SideWidgetContext.Provider
      value={{
        opened,
        setOpened,
        value,
        setValue,
        tasks,
        setModeFilter,
        setTasks,
        modeFilter,
        stagesOfPhase,
        setStagesOfPhase,
        projectsOfClient,
        setProjectsOfClient,
        setProjectSelectedView,
        projectSelectedView,
        setStageSelectedView,
        stageSelectedView
      }}
    >
      {children}
    </SideWidgetContext.Provider>
  );
};
