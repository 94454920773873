import React, {type ReactNode, useState} from 'react';

export const DraggingContext = React.createContext(null);

type DraggingContextProps = {
  children?: ReactNode;
};

export const DraggingContextProvider = ({children}: DraggingContextProps) => {
  const [draggingContext, setDraggingContext] = useState<boolean>(true);
  const [resizingContext, setResizingContext] = useState<boolean>(true);
  return (
    <DraggingContext.Provider
      value={{
        draggingContext,
        setDraggingContext,
        resizingContext,
        setResizingContext
      }}
    >
      {children}
    </DraggingContext.Provider>
  );
};
