/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
// import useLocales from '@/hooks/useLocales';
import * as React from 'react';
import useLocales from '@/hooks/useLocales';

type Props = {
  handleChange?: (value: any) => void;
  handleFocus?: (value: any) => void;
  isValidated?: React.Dispatch<React.SetStateAction<boolean>>;
  label?: string;
  isRequired?: boolean;
  type: string;
  defaultValue?: string;
  styles?: React.CSSProperties;
  classes?: string;
  labelClasses?: string;
  pattern?: string;
  compareEmail?: string;
  placeholder?: string;
};

const InputField = ({
  handleChange,
  label,
  type,
  defaultValue,
  labelClasses,
  isRequired,
  handleFocus,
  styles,
  isValidated,
  classes,
  compareEmail,
  placeholder
}: Props) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const [isSame, setIsSame] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>('');
  const {translate} = useLocales();

  React.useEffect(() => {
    setValue(`${defaultValue}`);
    if (defaultValue) {
      setValidated(true);
    }
  }, [defaultValue]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    setValue(value);
    handleChange?.(value);
  };

  const handleLostFocus = (val: string) => {
    let regex = null;
    if (type === 'text') {
      regex = /\p{L}/gu; // letters, spaces and hyphens
    }
    if (type === 'initial') {
      regex = /\p{L}/gu; // letters, spaces and periods
    }
    if (type === 'number') {
      regex = /^\d+$/; // only digits
    }
    if (type === 'email') {
      regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (compareEmail === val) {
        setValidated(false);
        isValidated?.(false);
      }
    } // email validation
    if (type === 'tel') {
      regex = /^\+?(\d\s*){9,11}\d$/; // 8-11 digists with spaces
    }
    if (type === 'postcode') {
      regex = /^\s*\d{4}(\s*-?\s*[A-Z]{2})\s*$/i; // 4digits,2letters,spaces,-
    }
    if (type === 'all') {
      regex = /./; // all including special characters
    }
    if (val.length < 1) {
      setValidated(false);
      isValidated?.(false);
    } else if (!regex?.test(val)) {
      setValidated(false);
      isValidated?.(false);
    } else if (compareEmail === val.toLowerCase()) {
      setValidated(false);
      isValidated?.(false);
      setIsSame(true);
    } else {
      setValidated(true);
      isValidated?.(true);
      setIsSame(false);
      handleChange?.(val);
      handleFocus?.(val);
    }
  };

  React.useEffect(() => {
    if (value) {
      handleLostFocus(value);
    } else {
      setValidated(!isRequired);
      isValidated?.(!isRequired);
    }
  }, [value]);

  return (
    <div className="material-textfield">
      <label className={`${labelClasses}`}>{label}</label>
      <input
        required={isRequired}
        type="text"
        value={value}
        style={styles}
        className={`${
          !validated && value && isRequired
            ? 'is-invalid'
            : value && validated && isRequired
              ? 'is-valid'
              : ''
        } ${classes}`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleTextChange(e)
        }
        placeholder={placeholder || ''}
        onBlur={e => {
          const {value} = e.target;
          if (value) {
            handleLostFocus(value);
          } else {
            setValidated(false);
            isValidated?.(false);
          }
        }}
      />
      {type === 'email' && isSame && !validated && value && isRequired && (
        <span className="text-error-field">{translate('same_email_used')}</span>
      )}
      {!validated && value && isRequired && (
        <span className="text-error-field">
          {translate('field_is_required')}
        </span>
      )}
    </div>
  );
};

export default InputField;
