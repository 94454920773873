import axios from 'axios';
import type {AxiosError, AxiosResponse} from 'axios';
import {setSession} from './jwt';
import env from '@/constants/env';

const baseURL = env.ApiServerUrl || window.location.origin;

const axiosInstance = axios.create({
  baseURL: baseURL
});

let isRefreshing = false;
let failedRequestsQueue: Array<{
  onSuccess: (token: string) => void;
  onFailure: (error: AxiosError) => void;
}> = [];

const refreshToken = async () => {
  const refresh = window.localStorage.getItem('refresh');
  const payload = window.localStorage.getItem('organization');
  const organization = JSON.parse(payload).id;

  try {
    const response = await axios.post(`${baseURL}/api/auth/token/refresh/`, {
      organization,
      refresh
    });
    const {
      access: newAccess,
      refresh: newRefresh,
      access_expiration: newAcessExpiration,
      refresh_expiration: newRefreshExpiration
    } = response.data;

    setSession(newAccess);
    localStorage.setItem('accessToken', newAccess);
    localStorage.setItem('accessTokenExpiration', newAcessExpiration);
    localStorage.setItem('refresh', newRefresh);
    localStorage.setItem('refreshExpiration', newRefreshExpiration);

    return newAccess;
  } catch (error) {
    console.error('Token refresh error:', error);
    throw error;
  }
};

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: any) => {
    const originalRequest = error.config;
    const alreadyRefreshed = originalRequest._alreadyRefreshed;

    if (
      (error.response?.status === 401 ||
        error.response?.data?.code === 'token_not_valid') &&
      !originalRequest._retry &&
      !alreadyRefreshed
    ) {
      if (isRefreshing) {
        // Wait for the token to be refreshed
        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (token: string) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              // Ensure originalRequest data is preserved
              if (originalRequest.data) {
                originalRequest.data = JSON.parse(originalRequest.data);
              }
              resolve(axiosInstance(originalRequest));
            },
            onFailure: (err: AxiosError) => reject(err)
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const newAccessToken = await refreshToken();
        if (!newAccessToken) {
          throw new Error('Failed to refresh token');
        }
        localStorage.setItem('accessToken', newAccessToken);

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        if (originalRequest.data) {
          originalRequest.data = JSON.parse(originalRequest.data);
        }

        const retryResponse = await axiosInstance(originalRequest);

        failedRequestsQueue.forEach(request =>
          request.onSuccess(newAccessToken)
        );
        failedRequestsQueue = [];

        return retryResponse;
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);

        failedRequestsQueue.forEach(request => request.onFailure(refreshError));
        failedRequestsQueue = [];

        localStorage.clear();
        window.location.href = '/authentication/login';
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
