import React from 'react';

const LoadingScreen: React.FC = () => {
  return (
    <section className="loading-screen h-full w-full flex align-middle justify-center">
      <div className="lds-facebook">
        <div />
        <div />
        <div />
      </div>
    </section>
  );
};

export default LoadingScreen;
