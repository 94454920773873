/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
/* eslint-disable @typescript-eslint/no-explicit-any */
import enflag from '@/assets/img/lang/en.png';
import nlflag from '@/assets/img/lang/nl.png';
import useLocales from '@/hooks/useLocales';
import {MenuItem, Select} from '@mui/material';
import React from 'react';
import {classNames} from '../utils/helper';

type LanguageSwitcherProps = {
  open?: boolean;
  classes?: string;
  showText?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
};

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  open,
  classes,
  showText,
  variant = 'standard'
}: LanguageSwitcherProps) => {
  const {allLang, currentLang, translate, onChangeLang} = useLocales();

  const checkFlag = (icon: string) => {
    switch (icon) {
      case 'en':
        return enflag;
      case 'nl':
        return nlflag;
      default:
        break;
    }
  };
  const checkLanguage = (label: string) => {
    switch (label) {
      case 'en':
        return 'English';
      case 'nl':
        return 'Dutch';
      default:
        break;
    }
  };

  return (
    <>
      <Select
        fullWidth={true}
        disableUnderline={true}
        value={currentLang.value}
        onChange={e => onChangeLang(e.target.value as string)}
        variant={variant}
        renderValue={(value: any) => {
          return (
            <div
              className={`flex items-center ${open ? '' : 'justify-center'} ${classes}`}
            >
              <span className="my-auto">
                <img
                  className="h-4 mr-2 w-7 my-auto"
                  src={checkFlag(value)}
                  alt="flag"
                />
              </span>
              {showText && (
                <span
                  className={classNames(
                    open ? 'text-light-blue ml-4 mr-4' : 'hidden'
                  )}
                >
                  {translate(checkLanguage(value))}
                </span>
              )}
            </div>
          );
        }}
      >
        {allLang.map(lang => (
          <MenuItem key={lang.value} value={lang.value}>
            <span className="mr-2 my-auto">
              <img
                className="h-6"
                src={checkFlag(lang.icon.split('flag')[0])}
                alt="flag"
              />
            </span>
            <span>{translate(lang.label)}</span>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default LanguageSwitcher;
