import React from 'react';
import '@/assets/style.css';
import '@/assets/scss/main.scss';
import {AuthProvider} from '@/contexts/JWTContext';
import Router from '@/router';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@/contexts/i18n';
import ThemeLocalization from '@/components/common/ThemeLocalization';
import {ErrorProvider} from '@/contexts/ErrorContext';
import {SideWidgetProvider} from '@/contexts/SideWidgetContext';

const App: React.FC = () => {
  return (
    <div className="min-h-full">
      <ThemeLocalization>
        <AuthProvider>
          <ErrorProvider>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick={true}
              rtl={false}
              pauseOnFocusLoss={true}
              draggable={false}
              pauseOnHover={true}
              theme="light"
            />
            <SideWidgetProvider>
              <Router />
            </SideWidgetProvider>
          </ErrorProvider>
        </AuthProvider>
      </ThemeLocalization>
    </div>
  );
};

export default App;
