export const checkRole = (allowedRoles: string[], category: string | null) => {
  const permissions = localStorage.getItem('permissions') ?? false;

  const userPermissions = JSON.parse(permissions || null);

  // check for owner and return true
  if (permissions && userPermissions === null) {
    return true;
  }

  // check for deleted permissions
  if (!permissions) {
    return false;
  }

  // no instance of permission
  if (category && userPermissions[category] === undefined) {
    return false;
  }

  // no specified roles
  if (allowedRoles.length === 0) {
    return true;
  }

  const userRoles: string[] = [];

  userPermissions[category]?.forEach((role: string) => {
    userRoles.push(role.toLowerCase());
  });

  if (userRoles.length === 0) {
    return false;
  }

  return userRoles.some(role => allowedRoles.includes(role));
};
