import React, {type ReactNode, useState} from 'react';

export const PageTitleContext = React.createContext(null);

type PageTitleProps = {
  children?: ReactNode;
};

export const PageTitleProvider = ({children}: PageTitleProps) => {
  const [pageTitle, setPageTitle] = useState<string>('');

  return (
    <PageTitleContext.Provider value={{pageTitle, setPageTitle}}>
      {children}
    </PageTitleContext.Provider>
  );
};
