/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import InputField from '@/components/common/InputField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {
  Button,
  Checkbox,
  Autocomplete,
  FormControl,
  FormLabel,
  TextField,
  Box
} from '@mui/material';
import useLocales from '@/hooks/useLocales';
import useError from '@/hooks/useError';
import type {AxiosError} from 'axios';
import {createFormFields, updateFormFields} from '@/apis/FormFields';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import LoadingScreen from '@/components/common/LoadingScreen';
import {toast} from 'react-toastify';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface ErrorResponse {
  non_field_errors: string[];
}
const FileUploadField = (props: any) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const [selectedFileType, setSelectedFileType] = React.useState([]);
  const {translate} = useLocales();
  const {handleErrors} = useError();
  const [label, setLabel] = React.useState('');
  const [helpText, setHelpText] = React.useState('');
  const [type, setType] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [description, setDescription] = React.useState('');
  const [maxNumber, setMaxNumber] = React.useState('');

  React.useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        submitField();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [label, helpText, type, maxNumber, description, selectedFileType]);

  React.useEffect(() => {
    if (props?.id) {
      setLabel(props?.label || '');
      setDescription(props?.description || '');
      setHelpText(props?.help_text || '');
      setSelectedFileType(props?.allowed_types || []);
      setMaxNumber(`${props?.max_size}` || '');
      setType(props?.multiple || false);
    } else {
      setLabel('');
      setDescription('');
      setHelpText('');
      setSelectedFileType([]);
      setMaxNumber('');
      setType(false);
    }
  }, [props]);

  const submitField = () => {
    props?.setRestoreFieldName('');
    if (label.length && description.length && selectedFileType.length) {
      setValidated(true);
    } else {
      setValidated(false);
      setLoading(true);
      return;
    }
    const id = props?.id;
    const load = {
      label,
      field_type: 'file',
      required: true,
      description,
      help_text: helpText,
      allowed_types: selectedFileType,
      max_size: Number(maxNumber),
      multiple: type
    };
    if (id) {
      updateField({...load, id});
    } else {
      createField(load);
    }
  };

  const createField = async (payload: any) => {
    setLoading(true);
    await createFormFields(payload)
      .then(async () => {
        setLoading(false);
        props?.setUpdated(true);
        toast.success(translate('submitted_success'));
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        const errorResponse = error?.response?.data as ErrorResponse;
        if (
          errorResponse.non_field_errors[0] ===
          'deleted_field_with_provided_label_already_exists'
        ) {
          props?.setRestoreFieldName(payload.label);
          toast.error(translate('field_already_exist'));
        } else {
          handleErrors(error?.response?.data);
        }
      });
  };

  const updateField = async (payload: any) => {
    setLoading(true);
    await updateFormFields(payload)
      .then(async () => {
        props?.setUpdated(true);
        setLoading(false);
        toast.success(translate('submitted_success'));
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        const errorResponse = error?.response?.data as ErrorResponse;
        if (
          errorResponse.non_field_errors[0] ===
          'deleted_field_with_provided_label_already_exists'
        ) {
          props?.setRestoreFieldName(payload.label);
          toast.error(translate('field_already_exist'));
        } else {
          handleErrors(error?.response?.data);
        }
      });
  };

  return (
    <div className="flex flex-col justify-between h-[70vh]">
      <div className="new-textfield-container mt-5">
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('title')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={label}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLabel(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('title')}
              error={Boolean(loading && label === '')}
              helperText={
                loading && label === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('description')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={description}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('description')}
              error={Boolean(loading && description === '')}
              helperText={
                loading && description === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <FormControl className="w-[300px]">
          <FormLabel className="text-[#212B36] mb-1">
            {translate('help_text')}
          </FormLabel>
          <TextField
            fullWidth={true}
            value={helpText}
            size="small"
            className="bg-white"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setHelpText(e.target.value);
              setLoading(false);
            }}
            placeholder={translate('help_text')}
            error={Boolean(loading && helpText === '')}
            helperText={
              loading && helpText === '' ? translate('required') : null
            }
          />
        </FormControl>
        <div className="my-5">
          <FormControlLabel
            control={
              <Switch
                defaultChecked={type}
                onClick={(e: any) => setType(e?.target?.checked)}
              />
            }
            disableTypography
            label={
              <div className="flex flex-col">
                <div className="text-[12px]">
                  {translate('multi_upload_label')}
                </div>
                <div className="text-[9px]">
                  {translate('multiple_upload_text')}
                </div>
              </div>
            }
          />
        </div>
        <div>
          <InputField
            isRequired={true}
            isValidated={setValidated}
            label={translate('max_upload_size')}
            placeholder={translate('max_upload_size')}
            defaultValue={maxNumber}
            handleChange={e => setMaxNumber(e)}
            type={'number'}
          />
        </div>
        <Box
          sx={{
            width: 300,
            maxWidth: '100%'
          }}
        >
          <FormControl fullWidth={true}>
            <FormLabel className="text-[#212B36]">
              {translate('upload_file_type')}
            </FormLabel>
            <Autocomplete
              multiple={true}
              options={['txt', 'docx', 'csv', 'pdf']}
              disableCloseOnSelect
              getOptionLabel={option => option}
              value={selectedFileType}
              size="small"
              onChange={(e, value) => setSelectedFileType(value)}
              renderOption={(props, option, {selected}) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{marginRight: 1}}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={params => <TextField {...params} />}
            />
            <small>{translate('upload_file_type_text')}</small>
          </FormControl>
        </Box>
      </div>
      {loading && validated && <LoadingScreen />}
      <div className="flex">
        <Button
          variant="contained"
          size="small"
          color="inherit"
          className="bg-[#144A6C] mr-5 px-7 h-[20px] text-white normal-case"
          onClick={submitField}
          disabled={!validated && loading}
        >
          {translate('save')}
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="inherit"
          className="bg-white mr-5 px-5 text-[#144A6C] h-[20px] normal-case border-0"
          onClick={() => {
            setLabel('');
            setType(false);
            setDescription('');
            setHelpText('');
          }}
        >
          {translate('cancel')}
        </Button>
      </div>
    </div>
  );
  // const payloadUpload = {
  //   name: '',
  //   label: '',
  //   description: '',
  //   indicator: '',
  //   type: 'upload',
  //   is_multiple_upload: false,
  //   size_limit: false,
  //   file_type: false
  // }
};

export default FileUploadField;
