/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import InputField from '@/components/common/InputField';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import {Grid, MenuItem, Select} from '@mui/material';
import useLocales from '@/hooks/useLocales';
import useError from '@/hooks/useError';
import type {AxiosError} from 'axios';
import {FormControl, FormLabel, TextField} from '@mui/material';
import {
  createFormFields,
  updateFormFields,
  getFieldChoices
} from '@/apis/FormFields';
import LoadingScreen from '@/components/common/LoadingScreen';
import EditOptionWarning from '../EditOptionWarning';
import {toast} from 'react-toastify';
import {
  createPositionFields,
  getPositionFieldChoices,
  updatePositionFields
} from '@/apis/PositionFields';
import {ExclamationTriangleIcon} from '@heroicons/react/20/solid';

interface ErrorResponse {
  non_field_errors: string[];
}

const SelectField = (props: any) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const {translate} = useLocales();
  const {handleErrors} = useError();
  const [label, setLabel] = React.useState('');
  const [helpText, setHelpText] = React.useState('');
  const [type, setType] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isNew, setIsNew] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [placeholder, setPlaceholder] = React.useState('');
  const [optionList, setOptionList] = React.useState([
    {
      value: '',
      id: ''
    }
  ]);
  const [displayType, setDisplayType] = React.useState('SELECT');
  const [newPositionField, setNewPositionField] = React.useState<string>('');
  const [fieldType, setFieldType] = React.useState('');

  React.useEffect(() => {
    const path = window.location.pathname;
    const segments = path.split('/');
    setNewPositionField(segments[segments.length - 1]);
  });

  React.useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        submitField();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [label, helpText, type, placeholder, optionList, displayType]);

  React.useEffect(() => {
    if (props?.id) {
      setLabel(props?.label || '');
      if (props?.field_type === 'multiplechoice') {
        setType(true);
        getFieldsChoices({
          fieldname: 'multiple_choice_field__id',
          lookup: 'exact',
          negation: false,
          value: `${props?.id}`
        });
      } else {
        setType(false);
        getFieldsChoices({
          fieldname: 'choice_field__id',
          lookup: 'exact',
          negation: false,
          value: `${props?.id}`
        });
      }
      setPlaceholder(props?.placeholder || '');
      setHelpText(props?.help_text || '');
      setDisplayType(props?.display_type);
      setOpenWarning(false);
      setFieldType(props?.field_type);
    } else {
      setLabel('');
      setPlaceholder('');
      setHelpText('');
      setType(false);
      setOptionList([
        {
          value: '',
          id: ''
        }
      ]);
      setIsNew(false);
    }
  }, [props]);

  const submitField = () => {
    if (label.length && optionList.length) {
      setValidated(true);
    } else {
      setValidated(false);
      setLoading(true);
      return;
    }
    const id = props?.id;
    const load = {
      label,
      field_type: type ? 'multiplechoice' : 'choice',
      placeholder,
      help_text: helpText,
      choices: optionList.map(el => {
        return {
          value: el.value,
          ...(el.id && {id: el.id})
        };
      }),
      display_type: displayType
    };
    if (id) {
      updateField({...load, id});
    } else {
      createField(load);
    }
  };

  const getFieldsChoices = async (payload: any) => {
    setLoading(true);
    const queryLoad = {
      filters: {
        operation: 'AND',
        predicates: [
          {...payload},
          {
            fieldname: 'is_deleted',
            lookup: 'exact',
            negation: false,
            value: 'false'
          }
        ]
      },
      pagination: {
        page: 1,
        page_size: 1000
      }
    };
    if (newPositionField === 'new_position_field') {
      await getPositionFieldChoices(queryLoad)
        .then((response: any) => {
          setLoading(false);
          const result = response?.data?.results;
          if (result.length) {
            setOptionList(result);
            setIsNew(true);
          } else {
            setOptionList([
              {
                value: '',
                id: ''
              }
            ]);
          }
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          handleErrors(error?.response?.data);
        });
    } else {
      await getFieldChoices(queryLoad)
        .then((response: any) => {
          setLoading(false);
          const result = response?.data?.results;
          if (result.length) {
            setOptionList(result);
            setIsNew(true);
          } else {
            setOptionList([
              {
                value: '',
                id: ''
              }
            ]);
          }
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          handleErrors(error?.response?.data);
        });
    }
  };

  const createField = async (payload: any) => {
    if (props.type === 'position_field') {
      setLoading(true);
      await createPositionFields(payload)
        .then(async () => {
          setLoading(false);
          props?.setUpdated(true);
          toast.success(translate('submitted_success'));
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          const errorResponse = error?.response?.data as ErrorResponse;
          if (
            errorResponse.non_field_errors[0] ===
            'field_with_provided_label_already_exists'
          ) {
            props?.setRestoreFieldName(payload.label);
            toast.error(translate('field_already_exist'));
          } else {
            handleErrors(error?.response?.data);
          }
        });
    } else {
      setLoading(true);
      await createFormFields(payload)
        .then(async () => {
          setLoading(false);
          props?.setUpdated(true);
          toast.success(translate('submitted_success'));
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          const errorResponse = error?.response?.data as ErrorResponse;
          if (
            errorResponse.non_field_errors[0] ===
            'field_with_provided_label_already_exists'
          ) {
            props?.setRestoreFieldName(payload.label);
            toast.error(translate('field_already_exist'));
          } else {
            handleErrors(error?.response?.data);
          }
        });
    }
  };

  const updateField = async (payload: any) => {
    if (newPositionField === 'new_position_field') {
      setLoading(true);
      await updatePositionFields(payload)
        .then(async () => {
          props?.setUpdated(true);
          setLoading(false);
          toast.success(translate('submitted_success'));
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          const errorResponse = error?.response?.data as ErrorResponse;
          if (
            errorResponse.non_field_errors[0] ===
            'field_with_provided_label_already_exists'
          ) {
            props?.setRestoreFieldName(payload.label);
            toast.error(translate('field_already_exist'));
          } else {
            handleErrors(error?.response?.data);
          }
        });
    } else {
      setLoading(true);
      await updateFormFields(payload)
        .then(async () => {
          props?.setUpdated(true);
          setLoading(false);
          toast.success(translate('submitted_success'));
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          const errorResponse = error?.response?.data as ErrorResponse;
          if (
            errorResponse.non_field_errors[0] ===
            'field_with_provided_label_already_exists'
          ) {
            props?.setRestoreFieldName(payload.label);
            toast.error(translate('field_already_exist'));
          } else {
            handleErrors(error?.response?.data);
          }
        });
    }
  };

  return (
    <div className="flex flex-col justify-between h-[70vh] overflow-y-scroll">
      {isNew && (
        <EditOptionWarning
          open={openWarning}
          setOpen={setOpenWarning}
          setIsNew={setIsNew}
        />
      )}
      <div className="new-textfield-container mt-5">
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('label')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={label}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLabel(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('label')}
              error={Boolean(loading && label === '')}
              helperText={
                loading && label === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('placeholder')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={placeholder}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPlaceholder(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('placeholder')}
            />
          </FormControl>
        </div>
        <div className="mb-3">
          <div className="text-[16px] text-[#212B36]">
            {translate('options')}
          </div>
          {loading && validated && <LoadingScreen />}
          <div className={`${optionList?.length > 3 && 'mt-2'}`}>
            {optionList?.map((option, index) => (
              <Grid
                key={option?.id || index}
                container={true}
                spacing={2}
                className="w-[300px] items-center"
              >
                <Grid item={true} xs={10}>
                  <div
                    onClick={() => {
                      if (option?.id && isNew) {
                        setOpenWarning(true);
                      }
                    }}
                  >
                    <InputField
                      label={''}
                      defaultValue={option?.value}
                      handleChange={e => {
                        optionList[index] = {
                          value: e,
                          id: option?.id || ''
                        };
                        setOptionList(optionList);
                      }}
                      placeholder={`${translate('option')} ${index + 1}`}
                      type={'text'}
                    />
                  </div>
                </Grid>
                <Grid item={true} xs={2} className="pt-1">
                  <CloseIcon
                    className="cursor-pointer"
                    onClick={() =>
                      setOptionList((prev: any) => {
                        if (prev.length > 1) {
                          prev.splice(index, 1);
                          return [...prev];
                        }
                        return [
                          {
                            value: '',
                            id: ''
                          }
                        ];
                      })
                    }
                  />
                </Grid>
              </Grid>
            ))}
          </div>
          <div className="flex items-center justify-center w-[300px]">
            <AddIcon
              className="text-[#144A6C] cursor-pointer"
              onClick={() =>
                setOptionList((prev: any) => [
                  ...prev,
                  {
                    value: '',
                    id: ''
                  }
                ])
              }
            />
          </div>
        </div>
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('display_type')}
            </FormLabel>
            <Select
              className="bg-white rounded-lg user-container"
              size="small"
              multiple={false}
              value={displayType}
              onChange={(e: any) => {
                setDisplayType(e.target.value);
                setLoading(false);
              }}
              displayEmpty
            >
              <MenuItem value="SELECT">{translate('dropdown')}</MenuItem>
              <MenuItem value="CHECKBOX">{translate('radio_button')}</MenuItem>
            </Select>
          </FormControl>
        </div>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={type}
              disabled={props?.id}
              onClick={(e: any) => setType(e?.target?.checked)}
            />
          }
          label={translate('multi_select')}
        />
      </div>
      <div className="flex">
        <div>
          {(fieldType === 'standard' || fieldType === 'dlubal') && (
            <div className="flex">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-dark-yellow"
                aria-hidden="true"
              />
              <span className="text-dark-yellow">
                {translate('not_editable')}
              </span>
            </div>
          )}
          <Button
            variant="contained"
            size="small"
            color="inherit"
            className="bg-[#144A6C] mr-5 px-7 h-[20px] text-white normal-case"
            onClick={submitField}
            disabled={
              (!validated && loading) ||
              fieldType === 'standard' ||
              fieldType === 'dlubal'
            }
          >
            {translate('save')}
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            className="bg-white mr-5 px-5 text-[#144A6C] h-[20px] normal-case border-0"
            onClick={() => {
              setLabel('');
              setPlaceholder('');
              setHelpText('');
            }}
          >
            {translate('cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
  // const payloadSelect = {
  //   name: '',
  //   label: '',
  //   placeholder: '',
  //   indicator: '',
  //   options: [],
  //   type: 'select',
  //   list_exist: false,
  //   is_multiple: false,
  // }
};

export default SelectField;
