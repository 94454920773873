/* eslint-disable max-len */
import React from 'react';
import {createRoot} from 'react-dom/client';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material/styles';
import {CssBaseline} from '@mui/material';
import App from '@/pages/App';
import {PageTitleProvider} from './contexts/PageTitleContext';
import {checkboxClasses} from '@mui/material/Checkbox';
import {DraggingContextProvider} from './contexts/DraggingContext';
import './instrument';

const rootElement =
  document.getElementById('app') || document.createElement('div');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootElement!);
// All `Portal`-related components need to have the the main app wrapper element as a container
// so that the are in the subtree under the element used in the `important` option of the Tailwind's config.
export const theme = createTheme({
  palette: {
    primary: {
      main: '#144A6C'
    }
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          zIndex: 999999999
        }
      }
    },
    MuiPopover: {
      defaultProps: {
        container: rootElement
      }
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#CBD5E1',
          [`&.${checkboxClasses.checked}`]: {
            color: '#7F170E'
          }
        }
      }
    }
  },
  zIndex: {
    mobileStepper: 100,
    fab: 105,
    speedDial: 105,
    appBar: 110,
    drawer: 120,
    modal: 130,
    snackbar: 140,
    tooltip: 150
  }
});

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PageTitleProvider>
          <DraggingContextProvider>
            <App />
          </DraggingContextProvider>
        </PageTitleProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
