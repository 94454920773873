/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, {type ReactNode, useState} from 'react';
import DesktopSideNav from '@/components/common/navigation/DesktopSideNav';
import {classNames} from '@/components/utils/helper';

type DesktopLayoutProps = {
  children?: ReactNode;
};

export default function DesktopLayout({children}: DesktopLayoutProps) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  return (
    <>
      <div className="h-full">
        {/* Static sidebar for desktop */}
        <div
          className={classNames(
            sidebarOpen ? 'w-28' : 'w-56',
            ' bg-white fixed inset-y-0 flex '
          )}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <DesktopSideNav open={sidebarOpen} setOpen={setSidebarOpen} />
        </div>
        <main
          className={classNames(
            sidebarOpen ? 'ml-28' : 'ml-56',
            'min-h-screen h-full flex-grow bg-[#E6F0F7]'
          )}
        >
          <div className="pt-4 px-4 h-full bg-[#E6F0F7] w-full noScrollbar">
            {/* Replace with your content */}
            {children}
            {/* /End replace */}
          </div>
        </main>
      </div>
    </>
  );
}
