/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import axiosInstance from '@/components/utils/axiosInstance';
import type {AxiosError, AxiosResponse} from 'axios';

const base = '/api/notification';

// Get Notifications
export const getNotification = (params?: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: params || {}
  };

  return axiosInstance
    .get(`${base}/`, config)
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const markNotificationRead = (payload: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axiosInstance
    .post(`${base}/mark-read/`, payload, config)
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const getUnreadCount = () => {
  const accessToken = localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {}
  };

  return axiosInstance
    .get(`${base}/unread-count/`, config)
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
