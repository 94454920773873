/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import useLocales from '@/hooks/useLocales';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import useError from '@/hooks/useError';
import type {AxiosError} from 'axios';
import {createFormFields, updateFormFields} from '@/apis/FormFields';
import LoadingScreen from '@/components/common/LoadingScreen';
import {FormControl, FormLabel, Switch, TextField} from '@mui/material';
import {toast} from 'react-toastify';
import {
  createPositionFields,
  updatePositionFields
} from '@/apis/PositionFields';
import {ExclamationTriangleIcon} from '@heroicons/react/20/solid';

interface ErrorResponse {
  non_field_errors: string[];
}

const DateField = (props: any) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const {translate} = useLocales();
  const {handleErrors} = useError();
  const [label, setLabel] = React.useState('');
  const [type, setType] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [placeholder, setPlaceholder] = React.useState('');
  const [newPositionField, setNewPositionField] = React.useState<string>('');
  const [fieldType, setFieldType] = React.useState('');

  React.useEffect(() => {
    const path = window.location.pathname;
    const segments = path.split('/');
    setNewPositionField(segments[segments.length - 1]);
  });

  React.useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        submitField();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [label, type, placeholder]);

  React.useEffect(() => {
    if (props?.id) {
      setLabel(props?.label || '');
      setPlaceholder(props?.placeholder || '');
      setType(props?.field_type === 'datetime');
      setFieldType(props?.field_type);
    } else {
      setLabel('');
      setPlaceholder('');
      setType(false);
    }
  }, [props]);

  const submitField = () => {
    props?.setRestoreFieldName('');
    if (label.length) {
      setValidated(true);
    } else {
      setValidated(false);
      setLoading(true);
      return;
    }
    const id = props?.id;
    const load = {
      label,
      field_type: type ? 'datetime' : 'date',
      placeholder
    };
    if (id) {
      updateField({...load, id});
    } else {
      createField(load);
    }
  };

  const createField = async (payload: any) => {
    if (props.type === 'position_field') {
      setLoading(true);
      await createPositionFields(payload)
        .then(async () => {
          setLoading(false);
          props?.setUpdated(true);
          toast.success(translate('submitted_success'));
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          const errorResponse = error?.response?.data as ErrorResponse;
          if (
            errorResponse.non_field_errors[0] ===
            'deleted_field_with_provided_label_already_exists'
          ) {
            props?.setRestoreFieldName(payload.label);
            toast.error(translate('field_already_exist'));
          } else {
            handleErrors(error?.response?.data);
          }
        });
    } else {
      setLoading(true);
      await createFormFields(payload)
        .then(async () => {
          setLoading(false);
          props?.setUpdated(true);
          toast.success(translate('submitted_success'));
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          const errorResponse = error?.response?.data as ErrorResponse;
          if (
            errorResponse.non_field_errors[0] ===
            'deleted_field_with_provided_label_already_exists'
          ) {
            props?.setRestoreFieldName(payload.label);
            toast.error(translate('field_already_exist'));
          } else {
            handleErrors(error?.response?.data);
          }
        });
    }
  };

  const updateField = async (payload: any) => {
    if (newPositionField === 'new_position_field') {
      setLoading(true);
      await updatePositionFields(payload)
        .then(async () => {
          props?.setUpdated(true);
          setLoading(false);
          toast.success(translate('submitted_success'));
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          const errorResponse = error?.response?.data as ErrorResponse;
          if (
            errorResponse.non_field_errors[0] ===
            'deleted_field_with_provided_label_already_exists'
          ) {
            props?.setRestoreFieldName(payload.label);
            toast.error(translate('field_already_exist'));
          } else {
            handleErrors(error?.response?.data);
          }
        });
    } else {
      setLoading(true);
      await updateFormFields(payload)
        .then(async () => {
          props?.setUpdated(true);
          setLoading(false);
          toast.success(translate('submitted_success'));
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          const errorResponse = error?.response?.data as ErrorResponse;
          if (
            errorResponse.non_field_errors[0] ===
            'deleted_field_with_provided_label_already_exists'
          ) {
            props?.setRestoreFieldName(payload.label);
            toast.error(translate('field_already_exist'));
          } else {
            handleErrors(error?.response?.data);
          }
        });
    }
  };

  return (
    <div className="flex flex-col justify-between h-[70vh]">
      <div className="new-textfield-container mt-5">
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('Label')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={label}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLabel(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('Label')}
              error={Boolean(loading && label === '')}
              helperText={
                loading && label === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('placeholder')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={placeholder}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPlaceholder(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('placeholder')}
            />
          </FormControl>
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={type}
                disabled={props?.id}
                onClick={(e: any) => setType(e?.target?.checked)}
              />
            }
            label={translate('include_time')}
          />
        </div>
      </div>
      {loading && validated && <LoadingScreen />}
      <div className="flex">
        <div>
          {(fieldType === 'standard' || fieldType === 'dlubal') && (
            <div className="flex">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-dark-yellow"
                aria-hidden="true"
              />
              <span className="text-dark-yellow">
                {translate('not_editable')}
              </span>
            </div>
          )}

          <Button
            variant="contained"
            size="small"
            color="inherit"
            className="bg-[#144A6C] mr-5 px-7 h-[20px] text-white normal-case"
            onClick={submitField}
            disabled={
              (!validated && loading) ||
              fieldType === 'standard' ||
              fieldType === 'dlubal'
            }
          >
            {translate('save')}
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            className="bg-white mr-5 px-5 text-[#144A6C] h-[20px] normal-case border-0"
            onClick={() => {
              setLabel('');
              setPlaceholder('');
            }}
          >
            {translate('cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DateField;
