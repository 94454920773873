/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, {type ReactNode} from 'react';

type DesktopLayoutProps = {
  children?: ReactNode;
};

export default function LogoOnlyLayout({children}: DesktopLayoutProps) {
  return (
    <>
      <div className="authPage bg-white">{children}</div>
    </>
  );
}
