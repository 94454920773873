/* eslint-disable max-len */
import React from 'react';
import {classNames, stringAvatar} from '@/components/utils/helper';
import {Avatar} from '@mui/material';

type AccountInlineProps = {
  open: boolean;
};

const AccountInline: React.FC<AccountInlineProps> = ({
  open
}: AccountInlineProps) => {
  const user = JSON.parse(localStorage.getItem('user'));
  return (
    <div
      className={`flex flex-col align-middle justify-center text-center pt-5 ${
        open ? 'pb-5' : 'pb-10'
      }`}
    >
      <div
        className={classNames(
          open ? 'h-28 w-28' : 'h-24 w-24',
          'rounded-full mx-auto bg-[#8be794] flex align-middle justify-center text-white text-3xl flex-col'
        )}
      >
        <Avatar
          className="h-full w-full text-2xl"
          {...stringAvatar(`${user?.first_name} ${user?.last_name}`)}
        />
      </div>
      <div className={classNames(open ? 'hideItem' : 'hidden', 'pt-2')}>
        <div className="text-md font-bold hak4-text-dark">
          {user?.first_name} {user?.last_name}
        </div>
      </div>
    </div>
  );
};

export default AccountInline;
