import React, {
  type ReactNode,
  createContext,
  useContext,
  useState
} from 'react';

type SettingsContextType = {
  activeTab: string;
  setActiveTab: (value: string) => void;
};

const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined
);

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }

  return context;
};

type SettingsProviderProps = {
  children: ReactNode;
};

export const SettingsProvider: React.FC<SettingsProviderProps> = ({
  children
}) => {
  const [activeTab, setActiveTab] = useState<string>('');

  return (
    <SettingsContext.Provider value={{activeTab, setActiveTab}}>
      {children}
    </SettingsContext.Provider>
  );
};
