/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type AccordionType = {
  number?: number;
  title: string;
  description: string;
  id: string;
  color?: string;
};

const AccordionWithOtherWidget = (props: {
  details: AccordionType;
  handleChange: any;
  expanded?: string | false;
  classes?: string;
  children?:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal;
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Accordion
      expanded={expanded === props?.details?.id}
      onChange={handleChange(props?.details?.id)}
      className={props?.classes}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${props?.details?.id}-content`}
        id={`${props?.details?.id}-header`}
      >
        <div className="flex justify-between w-full py-2">
          <Typography className="ml-4">{props?.details?.title}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>{props?.children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionWithOtherWidget;
