/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import useLocales from '@/hooks/useLocales';
import useError from '@/hooks/useError';
import type {AxiosError} from 'axios';
import {createImageFields, updateImageFields} from '@/apis/FormFields';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {FormControl, FormLabel, TextField, Button, Grid} from '@mui/material';
import LoadingScreen from '@/components/common/LoadingScreen';
import {VisuallyHiddenInput} from '@/components/utils/helper';
import {toast} from 'react-toastify';
import {ExclamationTriangleIcon} from '@heroicons/react/20/solid';

interface ErrorResponse {
  non_field_errors: string[];
}
const PictureUploadField = (props: any) => {
  const [optionList, setOptionList] = React.useState([]);
  const [validated, setValidated] = React.useState<boolean>(false);
  const {translate} = useLocales();
  const {handleErrors} = useError();
  const [label, setLabel] = React.useState('');
  const [helpText, setHelpText] = React.useState('');
  const [type, setType] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [description, setDescription] = React.useState('');
  const [maxNumber, setMaxNumber] = React.useState('');
  const [fieldType, setFieldType] = React.useState('');

  React.useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        submitField();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [label, helpText, type, optionList, description, maxNumber]);

  React.useEffect(() => {
    if (props?.id) {
      setLabel(props?.label || '');
      setDescription(props?.description || '');
      setHelpText(props?.help_text || '');
      setOptionList(props?.choices || []);
      setMaxNumber(`${props?.max_size}` || '');
      setType(props?.multiple || false);
      setFieldType(props?.field_type);
    } else {
      setLabel('');
      setDescription('');
      setHelpText('');
      setOptionList([]);
      setMaxNumber('');
      setType(false);
    }
  }, [props]);

  const submitField = () => {
    props?.setRestoreFieldName('');
    if (label.length && description.length && optionList.length) {
      setValidated(true);
    } else {
      setValidated(false);
      setLoading(true);
      return;
    }

    const existingList = optionList.filter(file => !!file?.id);
    const newList = optionList.filter(file => !file?.id);
    const id = props?.id;
    const formData = new FormData();
    formData.append('label', label);
    formData.append('field_type', 'image');
    formData.append('description', description);
    formData.append('help_text', helpText);
    formData.append('max_size', `${maxNumber}`);
    formData.append('multiple', `${type}`);
    newList.map(file => {
      formData.append('images', file);
    });
    if (id) {
      // formData.append('id', id);
      existingList.map(file => {
        formData.append('existing_choices', file?.id);
      });
      updateField({fieldData: formData, id});
    } else {
      createField(formData);
    }
  };

  const createField = async (payload: any) => {
    setLoading(true);
    await createImageFields(payload)
      .then(async () => {
        setLoading(false);
        props?.setUpdated(true);
        toast.success(translate('submitted_success'));
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        const errorResponse = error?.response?.data as ErrorResponse;
        if (
          errorResponse.non_field_errors[0] ===
          'deleted_field_with_provided_label_already_exists'
        ) {
          props?.setRestoreFieldName(payload.label);
          toast.error(translate('field_already_exist'));
        } else {
          handleErrors(error?.response?.data);
        }
      });
  };

  const updateField = async (payload: any) => {
    setLoading(true);
    await updateImageFields(payload)
      .then(async () => {
        props?.setUpdated(true);
        setLoading(false);
        toast.success(translate('submitted_success'));
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        const errorResponse = error?.response?.data as ErrorResponse;
        if (
          errorResponse.non_field_errors[0] ===
          'deleted_field_with_provided_label_already_exists'
        ) {
          props?.setRestoreFieldName(payload.label);
          toast.error(translate('field_already_exist'));
        } else {
          handleErrors(error?.response?.data);
        }
      });
  };

  // const getBase64 = (file: Blob) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputFile = event.target.files[0];
    if (!inputFile) {
      return;
    }
    setOptionList(prev => [...prev, inputFile]);
  };

  return (
    <div className="flex flex-col justify-between h-[70vh]">
      <div className="new-textfield-container mt-5">
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('title')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={label}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLabel(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('title')}
              error={Boolean(loading && label === '')}
              helperText={
                loading && label === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('description')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={description}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('description')}
              error={Boolean(loading && description === '')}
              helperText={
                loading && description === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl className="w-[300px]">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('help_text')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={helpText}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHelpText(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('help_text')}
              error={Boolean(loading && helpText === '')}
              helperText={
                loading && helpText === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div className="my-5">
          <div className="text-[16px] text-[#212B36]">
            {translate('option')}
          </div>
          <Grid
            container={true}
            spacing={4}
            className="items-center justify-start m-0 h-[200px] overflow-y-auto overflow-x-hidden"
          >
            {optionList.map((option, index) => (
              <Grid key={index} item={true} xs={3} className="m-0 mr-3 p-1 ">
                <div className="border w-[120px] text-[12px] h-[120px] rounded-md relative">
                  <img
                    src={
                      option?.image
                        ? option?.image
                        : URL.createObjectURL(option)
                    }
                    alt=""
                    className="w-full h-full rounded-md"
                  />
                  <CloseIcon
                    className="absolute cursor-pointer top-0 right-0 bg-white"
                    onClick={() =>
                      setOptionList(prev => {
                        if (prev.length > 1) {
                          prev.splice(index, 1);
                          return [...prev];
                        }
                        return ['http://tinyurl.com/48bxz9ec'];
                      })
                    }
                  />
                </div>
              </Grid>
            ))}
            <Grid item={true} xs={3} className="m-0 p-1 ">
              <Button
                component="label"
                className="border border-1 p-4 text-primary text-[12px] cursor-pointer w-[140px] h-[120px] rounded-md"
              >
                <div className=" flex flex-col justify-center">
                  <div className="flex justify-center">
                    <FileUploadOutlinedIcon className="text-[18px]" />
                  </div>
                  <div className="flex justify-center text-[12px]">
                    {translate('drag_file')}
                  </div>
                  <div className="flex justify-center text-[12px]">
                    {translate('or')}
                  </div>
                  <div className="flex justify-center">
                    <div className="border w-[120px] h-[25px] flex justify-center items-center text-[10px]">
                      {translate('select_file')}
                    </div>
                  </div>
                </div>
                <VisuallyHiddenInput
                  type="file"
                  onChange={e => handleFileChange(e)}
                />
              </Button>
            </Grid>
          </Grid>
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={type}
                onClick={(e: any) => setType(e?.target?.checked)}
              />
            }
            disableTypography
            label={
              <div className="flex flex-col">
                <div className="text-[12px]">
                  {translate('multi_upload_label')}
                </div>
                <div className="text-[9px]">
                  {translate('multiple_upload_text')}
                </div>
              </div>
            }
          />
        </div>
      </div>
      {loading && validated && <LoadingScreen />}
      <div className="flex">
        <div>
          {(fieldType === 'standard' || fieldType === 'dlubal') && (
            <div className="flex">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-dark-yellow"
                aria-hidden="true"
              />
              <span className="text-dark-yellow">
                {translate('not_editable')}
              </span>
            </div>
          )}
          <Button
            variant="contained"
            size="small"
            color="inherit"
            className="bg-[#144A6C] mr-5 px-7 h-[20px] text-white normal-case"
            onClick={submitField}
            disabled={
              (!validated && loading) ||
              fieldType === 'standard' ||
              fieldType === 'dlubal'
            }
          >
            {translate('save')}
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            className="bg-white mr-5 px-5 text-[#144A6C] h-[20px] normal-case border-0"
            onClick={() => {
              setLabel('');
              setType(false);
              setDescription('');
              setHelpText('');
            }}
          >
            {translate('cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
  // const payloadPicture = {
  //   name: '',
  //   label: '',
  //   description: '',
  //   indicator: '',
  //   options: [],
  //   type: 'picture',
  //   is_multiple: false,
  // }
};

export default PictureUploadField;
